export function validEmail(email) {
  if (
    email.search(
      /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/
    ) != -1
  ) {
    return true;
  } else {
    return false;
  }
}

export function validStringEmpty(str) {
  return !str || str.trim().length === 0;
}

export function validIsNumberAndBiggerThanZero(str) {
  var reg=/^[0-9]+.?[0-9]*$/;

  if(!reg.test(str)){
    return false;
  }else{
    var temp = parseInt(str)
    if(temp<0){
      return false;
    }
  }

  return true;
}

export function validIsIntegerAndBiggerThanZero(str) {
  var reg=/^[1-9]+[0-9]*]*$/;

  if(!reg.test(str)){
    return false;
  }else{
    var temp = parseInt(str)
    if(temp<0){
      return false;
    }
  }

  return true;
}
