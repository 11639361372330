<template lang="pug">
.overview.pa-0.pa-md-7.position-relative
  v-row.mt-0.py-0.py-md-7.text-center
    v-col.text-left.d-none.d-md-block(cols="12", md="6", lg="6")
      h2.text_f_primary 球員分析
    v-col.bottom_actions.d-flex(cols="12", md="6", lg="6")
      select.mr-3(v-model="country", @change="filterChange")
        option(value="ALL") 全部球員
        option(value="LOCAL") 本土
        option(value="FOREIGN") 洋將
      select.mr-3(v-model="position", @change="filterChange")
        option(value="ALL") 所有位置
        option(value="G") G
        option(value="F") F
        option(value="C") C
      input(placeholder="球員姓名", v-model="nameText")
  v-row.table_row.desktop.mt-0.d-none.d-md-block
    v-col.table.f_table.table_col
      table
        thead
          tr.bottom_head
            sticky-column.bdl.bdr2(
              style="width: 70px; min-width: 70px",
              :offset="0"
            ) 球員
            sticky-column.bdl.bdr2(
              style="width: 130px; min-width: 130px",
              :offset="70"
            ) 隊伍
            sticky-column.bdl.bdr2(
              style="width: 50px; min-width: 50px",
              :offset="200"
            ) 位置
            th(
              style="cursor: pointer",
              @click="sortClick('pick_count', 'INFO')",
              :class="{ sort_up: sortValue.type == 'pick_count' && sortValue.order == 'ASC', sort_down: sortValue.type == 'pick_count' && sortValue.order == 'DESC' }"
            ) 
              span 選取次數
              font-awesome-icon.ml-2(:icon="getTableSort('pick_count')")
            th(
              style="cursor: pointer",
              @click="sortClick('add_count', 'INFO')",
              :class="{ sort_up: sortValue.type == 'add_count' && sortValue.order == 'ASC', sort_down: sortValue.type == 'add_count' && sortValue.order == 'DESC' }"
            ) 
              span 加入次數
              font-awesome-icon.ml-2(:icon="getTableSort('add_count')")
            th(
              style="cursor: pointer",
              @click="sortClick('drop_count', 'INFO')",
              :class="{ sort_up: sortValue.type == 'drop_count' && sortValue.order == 'ASC', sort_down: sortValue.type == 'drop_count' && sortValue.order == 'DESC' }"
            ) 
              span 丟掉次數
              font-awesome-icon.ml-2(:icon="getTableSort('drop_count')")
            th(
              style="cursor: pointer",
              @click="sortClick('current_roster_rate', 'INFO')",
              :class="{ sort_up: sortValue.type == 'current_roster_rate' && sortValue.order == 'ASC', sort_down: sortValue.type == 'current_roster_rate' && sortValue.order == 'DESC' }"
            ) 
              span 正在使用率
              font-awesome-icon.ml-2(
                :icon="getTableSort('current_roster_rate')"
              )
            th(
              style="cursor: pointer",
              @click="sortClick('current_value', 'INFO')",
              :class="{ sort_up: sortValue.type == 'current_value' && sortValue.order == 'ASC', sort_down: sortValue.type == 'current_value' && sortValue.order == 'DESC' }"
            ) 
              span 評分
              font-awesome-icon.ml-2(:icon="getTableSort('current_value')")
            th(
              style="cursor: pointer",
              @click="sortClick('avg_reb', 'BOX')",
              :class="{ sort_up: sortValue.type == 'avg_reb' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_reb' && sortValue.order == 'DESC' }"
            ) 
              span 籃板
              font-awesome-icon.ml-2(:icon="getTableSort('avg_reb')")
            th(
              style="cursor: pointer",
              @click="sortClick('avg_ast', 'BOX')",
              :class="{ sort_up: sortValue.type == 'avg_ast' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_ast' && sortValue.order == 'DESC' }"
            ) 
              span 助攻
              font-awesome-icon.ml-2(:icon="getTableSort('avg_ast')")
            th(
              style="cursor: pointer",
              @click="sortClick('avg_stl', 'BOX')",
              :class="{ sort_up: sortValue.type == 'avg_stl' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_stl' && sortValue.order == 'DESC' }"
            ) 
              span 抄截
              font-awesome-icon.ml-2(:icon="getTableSort('avg_stl')")
            th(
              style="cursor: pointer",
              @click="sortClick('avg_blk', 'BOX')",
              :class="{ sort_up: sortValue.type == 'avg_blk' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_blk' && sortValue.order == 'DESC' }"
            ) 
              span 火鍋
              font-awesome-icon.ml-2(:icon="getTableSort('avg_blk')")
            th(
              style="cursor: pointer",
              @click="sortClick('avg_pts', 'BOX')",
              :class="{ sort_up: sortValue.type == 'avg_pts' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_pts' && sortValue.order == 'DESC' }"
            ) 
              span 得分
              font-awesome-icon.ml-2(:icon="getTableSort('avg_pts')")
        tbody
          tr(v-for="(player, index) in currentDraftData", :key="index")
            sticky-column.bdl.bdr(
              style="width: 70px; min-width: 70px",
              :offset="0"
            ) {{ player.name }}
            sticky-column.bdr(
              style="width: 130px; min-width: 130px",
              :offset="70"
            ) {{ player.team_name }}
            sticky-column.bdr2(
              style="width: 50px; min-width: 50px",
              :offset="200"
            ) {{ player.position }}
            td {{ player.pick_count }}
            td {{ player.add_count }}
            td {{ player.drop_count }}
            td {{ (player.current_roster_rate * 100).toFixed(1) + '%' }}
            td {{ player.current_value }}
            td {{ player.box.avg_reb != undefined ? player.box.avg_reb.toFixed(1) : '-' }}
            td {{ player.box.avg_ast != undefined ? player.box.avg_ast.toFixed(1) : '-' }}
            td {{ player.box.avg_stl != undefined ? player.box.avg_stl.toFixed(1) : '-' }}
            td {{ player.box.avg_blk != undefined ? player.box.avg_blk.toFixed(1) : '-' }}
            td {{ player.box.avg_pts != undefined ? player.box.avg_pts.toFixed(1) : '-' }}
  v-row.pagination.desktop.d-none.d-md-block
    v-col
      v-pagination(
        v-model="currentPage",
        :length="totalPages",
        prev-icon="mdi-menu-left",
        next-icon="mdi-menu-right",
        circle
      )
  .table_row.mobile.sticky.mt-0.d-md-none(
    :style="[{ height: $store.state.screenHeight - 203 + 'px' }, { top: $store.state.navbarHeight + 'px' }]"
  )
    .table.f_table.table_col(
      :style="{ maxHeight: $store.state.screenHeight - 203 + 'px' }"
    )
      table
        thead
          tr.bottom_head
            th(style="min-width: 45px") 位置
            th(
              style="cursor: pointer",
              @click="sortClick('pick_count', 'INFO')",
              :class="{ sort_up: sortValue.type == 'pick_count' && sortValue.order == 'ASC', sort_down: sortValue.type == 'pick_count' && sortValue.order == 'DESC' }"
            ) 
              span 選取次數
              font-awesome-icon.ml-2(:icon="getTableSort('pick_count')")
            th(
              style="cursor: pointer",
              @click="sortClick('add_count', 'INFO')",
              :class="{ sort_up: sortValue.type == 'add_count' && sortValue.order == 'ASC', sort_down: sortValue.type == 'add_count' && sortValue.order == 'DESC' }"
            ) 
              span 加入次數
              font-awesome-icon.ml-2(:icon="getTableSort('add_count')")
            th(
              style="cursor: pointer",
              @click="sortClick('drop_count', 'INFO')",
              :class="{ sort_up: sortValue.type == 'drop_count' && sortValue.order == 'ASC', sort_down: sortValue.type == 'drop_count' && sortValue.order == 'DESC' }"
            ) 
              span 丟掉次數
              font-awesome-icon.ml-2(:icon="getTableSort('drop_count')")
            th(
              style="cursor: pointer",
              @click="sortClick('current_roster_rate', 'INFO')",
              :class="{ sort_up: sortValue.type == 'current_roster_rate' && sortValue.order == 'ASC', sort_down: sortValue.type == 'current_roster_rate' && sortValue.order == 'DESC' }"
            ) 
              span 正在使用率
              font-awesome-icon.ml-2(
                :icon="getTableSort('current_roster_rate')"
              )
            th(
              style="cursor: pointer",
              @click="sortClick('current_value', 'INFO')",
              :class="{ sort_up: sortValue.type == 'current_value' && sortValue.order == 'ASC', sort_down: sortValue.type == 'current_value' && sortValue.order == 'DESC' }"
            ) 
              span 評分
              font-awesome-icon.ml-2(:icon="getTableSort('current_value')")
            th(
              style="cursor: pointer",
              @click="sortClick('avg_reb', 'BOX')",
              :class="{ sort_up: sortValue.type == 'avg_reb' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_reb' && sortValue.order == 'DESC' }"
            ) 
              span 籃板
              font-awesome-icon.ml-2(:icon="getTableSort('avg_reb')")
            th(
              style="cursor: pointer",
              @click="sortClick('avg_ast', 'BOX')",
              :class="{ sort_up: sortValue.type == 'avg_ast' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_ast' && sortValue.order == 'DESC' }"
            ) 
              span 助攻
              font-awesome-icon.ml-2(:icon="getTableSort('avg_ast')")
            th(
              style="cursor: pointer",
              @click="sortClick('avg_stl', 'BOX')",
              :class="{ sort_up: sortValue.type == 'avg_stl' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_stl' && sortValue.order == 'DESC' }"
            ) 
              span 抄截
              font-awesome-icon.ml-2(:icon="getTableSort('avg_stl')")
            th(
              style="cursor: pointer",
              @click="sortClick('avg_blk', 'BOX')",
              :class="{ sort_up: sortValue.type == 'avg_blk' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_blk' && sortValue.order == 'DESC' }"
            ) 
              span 火鍋
              font-awesome-icon.ml-2(:icon="getTableSort('avg_blk')")
            th(
              style="cursor: pointer",
              @click="sortClick('avg_pts', 'BOX')",
              :class="{ sort_up: sortValue.type == 'avg_pts' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_pts' && sortValue.order == 'DESC' }"
            ) 
              span 得分
              font-awesome-icon.ml-2(:icon="getTableSort('avg_pts')")
        tbody
          template(v-for="(player, index) in currentDraftData")
            tr
              sticky-column.bdl(
                colspan="4",
                style="width: 100px; min-width: 100px",
                :offset="0"
              ) 
                .player_container
                  .position {{ player.position }}
                  .player_info
                    p.player {{ player.name }}
                    p.team {{ player.team_name }}
              td(colspan="8")
            tr
              td
              td {{ player.pick_count }}
              td {{ player.add_count }}
              td {{ player.drop_count }}
              td {{ (player.current_roster_rate * 100).toFixed(1) + '%' }}
              td {{ player.current_value }}
              td {{ player.box.avg_reb != undefined ? player.box.avg_reb.toFixed(1) : '-' }}
              td {{ player.box.avg_ast != undefined ? player.box.avg_ast.toFixed(1) : '-' }}
              td {{ player.box.avg_stl != undefined ? player.box.avg_stl.toFixed(1) : '-' }}
              td {{ player.box.avg_blk != undefined ? player.box.avg_blk.toFixed(1) : '-' }}
              td {{ player.box.avg_pts != undefined ? player.box.avg_pts.toFixed(1) : '-' }}
  .pagination.mobile.d-md-none.d-flex.align-center.justify-center
    v-pagination(
      v-model="currentPage",
      :length="totalPages",
      prev-icon="mdi-menu-left",
      next-icon="mdi-menu-right",
      circle
    )
</template>

<script>
import { getSeasonData, getSeasonDraftData } from "@/api/fantasy";
import { validStringEmpty } from "@/utils/validate";
import StickyColumn from "@/components/common/Table/StickyColumn";

export default {
  name: "Overview",
  metaInfo() {
    return {
      title: this.metaData.title,
      meta: [
        {
          name: "description",
          content: this.metaData.description,
        },
        {
          name: "keywords",
          content: this.metaData.keywords,
        },
        { property: "og:site_name", content: this.metaData.siteTitle },
        {
          property: "og:title",
          content: this.metaData.title,
        },
        {
          property: "og:description",
          content: this.metaData.description,
        },
        { name: "og:url", content: this.metaData.url },
        { name: "og:image", content: this.metaData.image },
        { name: "smo_type", content: this.metaData.smoType },
      ],
    };
  },
  components: {
    StickyColumn,
  },
  data() {
    return {
      seasonData: {},
      draftData: [],
      sortDraftData: [],
      currentDraftData: [],

      country: "ALL",
      position: "ALL",
      nameText: "",

      currentPage: 1,
      totalPages: 1,
      perPage: 10,

      playerList: [
        {
          name: "XXX",
          avgPick: 1.3,
          img: "",
          avgRound: "_",
          percentDrafted: 400,
        },
      ],

      sortValue: {
        type: "",
        order: "",
      },

      metaData: {
        siteTitle: "籃球數據分析Game Changer",
        title: "GC Fantasy 球員分析 | Game Changer",
        description: "GC Fantasy 球員分析",
        keywords: "Game changer,籃球,會員,GC Fantasy",
        url: "https://www.gamechanger.tw/fantasy/draft",
        image: "",
        smoType: "website",
      },
    };
  },
  watch: {
    currentPage() {
      this.getCurrentDraftData();
    },
    nameText() {
      this.filterChange();
    },
  },
  created() {
    this.getSeasonData();
  },
  methods: {
    getSeasonData() {
      let temp = {
        state: "IN_SEASON",
      };
      getSeasonData(temp).then((response) => {
        this.seasonData = response.data[0];
        this.getSeasonDraftData(null, null, null);
      });
    },
    getSeasonDraftData(country, position, keyword) {
      let temp = {
        season_id: this.seasonData.id,
        country: country,
        position: position,
        keyword: keyword,
      };
      getSeasonDraftData(temp).then((response) => {
        this.draftData = response.data;
        this.sortDraftData = Object.assign([], this.draftData);
        this.currentPage = 1;
        this.getCurrentDraftData();
      });
    },
    getCurrentDraftData() {
      this.totalPages = Math.ceil(this.sortDraftData.length / this.perPage);
      this.currentDraftData = [];
      let number = this.perPage * this.currentPage;
      this.currentDraftData = this.sortDraftData.slice(
        number - this.perPage,
        number
      );
    },
    filterChange() {
      this.sortValue.type = "";
      this.sortValue.order = "";
      let tempCountry;
      let tempPosition;
      let tempNameText;
      if (this.country == "ALL") {
        tempCountry = null;
      } else {
        tempCountry = this.country;
      }
      if (this.position == "ALL") {
        tempPosition = null;
      } else {
        tempPosition = this.position;
      }
      if (validStringEmpty(this.nameText)) {
        tempNameText = null;
      } else {
        tempNameText = this.nameText;
      }
      this.getSeasonDraftData(tempCountry, tempPosition, tempNameText);
    },
    getTableSort(type) {
      if (type == this.sortValue.type) {
        if ("ASC" == this.sortValue.order) {
          return ["fas", "caret-up"];
        } else {
          return ["fas", "caret-down"];
        }
      } else {
        return ["fas", "sort"];
      }
    },
    sortClick(type, sortData) {
      if (type == this.sortValue.type) {
        if (this.sortValue.order == "DESC") {
          this.sortValue.order = "ASC";
          this.sortDraftData = this.sortData(
            this.draftData,
            type,
            sortData,
            "ASC"
          );
        } else if (this.sortValue.order == "ASC") {
          this.sortValue.type = "";
          this.sortValue.order = "";
          this.sortDraftData = this.draftData;
        }
      } else {
        this.sortValue.type = type;
        this.sortValue.order = "DESC";
        this.sortDraftData = this.sortData(
          this.draftData,
          type,
          sortData,
          "DESC"
        );
      }
      this.getCurrentDraftData();
    },
    sortData(allList, sortKey, sortData, order) {
      let tempList = Object.assign([], allList);
      if ("INFO" == sortData) {
        if ("ASC" == order) {
          return tempList.sort(function (a, b) {
            return a[sortKey] - b[sortKey];
          });
        } else if ("DESC" == order) {
          return tempList.sort(function (a, b) {
            return b[sortKey] - a[sortKey];
          });
        }
      } else if ("BOX" == sortData) {
        if ("ASC" == order) {
          return tempList.sort(function (a, b) {
            return a.box[sortKey] - b.box[sortKey];
          });
        } else if ("DESC" == order) {
          return tempList.sort(function (a, b) {
            return b.box[sortKey] - a.box[sortKey];
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/common/table";
@import "@/assets/style/common/pagination";
::placeholder {
  /* CSS 3 標準 */

  color: #c1c7ce;
}

select,
input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  outline: none;
  background: #ffffff;
  border: 1px solid #d2d4d6;
  border-radius: 5px;
  color: #9a9fa8;
}

select {
  background: url("~@/assets/img/fantasy/f_dropdwon_arrow.png") no-repeat right
    center;
  background-position: calc(100% - 10px) center;
}

input {
  box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, 0.03),
    inset 1px 1px 3px 0 rgba(0, 0, 0, 0.2);
  background: url("~@/assets/img/fantasy/f_search.png") no-repeat right center;
  background-position: calc(100% - 10px) center;
}

::v-deep .theme--light.v-pagination .v-pagination__item {
  background: #ffffff;
  color: #979797;
}

::v-deep .v-pagination.v-pagination .primary {
  background-color: #51769d !important;
  border-color: #51769d !important;
  color: white;
}

.gold {
  color: #e2a852 !important;
  font-weight: bold;
}

.sliver {
  color: #969696 !important;
  font-weight: bold;
}

.copper {
  color: #a97f5b !important;
  font-weight: bold;
}

@media (min-width: 960px) {
  select:active,
  input:active,
  textarea:active {
    font-size: 16px;
  }
}

@media (max-width: 959px) {
  select:active,
  input:active,
  textarea:active {
    font-size: 14px;
  }

  .table_row.mobile {
    padding-left: 0;
    padding-right: 0;

    .table {
      padding-bottom: 30px;
    }
  }
  .bottom_actions {
    width: 100%;
    position: fixed;
    bottom: 45px;
    z-index: 1;
    background: #f8f9fa;
    box-shadow: 0 -2px 14px 0 rgb(200 200 200 / 50%);
    padding: 12px;
    margin: 0;
    left: 0;
    height: 60px;

    input,
    select {
      padding: 6px;
      font-size: 12px;
    }
  }

  .pagination.mobile {
    height: 50px;
    position: fixed;
    bottom: 105px;
    left: 0;
    width: 100%;
    z-index: 1;
  }

  .sticky {
    thead {
      position: -webkit-sticky; /* for Safari */
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }
  .player_container {
    text-align: left;
    display: flex;
    align-items: center;

    .position {
      display: block;
      font-size: 13px;
      width: 33px;
      height: 33px;
      line-height: 33px;
      text-align: center;
    }

    .player_info {
      margin-left: 10px;

      p {
        margin: 0;
        line-height: 30px;
      }

      span {
        margin-left: 5px;
      }
    }
    .pos {
      color: #8d8d8d;
    }

    .opponent {
      color: #8e94d6;
    }

    .vs {
      color: #a3a3a3;
    }
  }
}
</style>
