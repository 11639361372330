import request from "@/utils/requestFantasy";
import store from "@/store";

export function getSeasonData(params) {
  return request({
    url: "/user/fantasy_season_data/",
    method: "get",
    params,
  });
}

export function getUserSeasonData(params) {
  return request({
    url: "/user/fantasy_season_data/",
    method: "get",
    params,
    headers: { Authorization: "JWT " + store.state.user.accessToken },
  });
}

export function getOverviewData(params) {
  return request({
    url: "/fantasy_season_overview/",
    method: "get",
    params,
  });
}

export function getOverviewDataBySearch(params) {
  return request({
    url: "/fantasy_season_overview_search/",
    method: "get",
    params,
  });
}

export function getUserTeamData(params) {
  return request({
    url: "/fantasy_user_team/",
    method: "get",
    params,
  });
}

export function getOverviewDataByUser(params) {
  return request({
    url: "/user/fantasy_season_overview/",
    method: "get",
    params,
    headers: { Authorization: "JWT " + store.state.user.accessToken },
  });
}

export function getSeasonDraftData(params) {
  return request({
    url: "/fantasy_season_draft_data/",
    method: "get",
    params,
  });
}

export function checkMyTeam(params) {
  return request({
    url: "/user/check_my_team/",
    method: "get",
    params,
    headers: { Authorization: "JWT " + store.state.user.accessToken },
  });
}

export function createTeam(data) {
  return request({
    url: "/user/create_team/",
    method: "post",
    data,
    headers: { Authorization: "JWT " + store.state.user.accessToken },
  });
}

export function getMyTeamByTab(params) {
  return request({
    url: "user/my_team/",
    method: "get",
    params,
    headers: { Authorization: "JWT " + store.state.user.accessToken },
  });
}

export function setDailyRoster(data) {
  return request({
    url: "user/set_daily_roster/",
    method: "post",
    data,
    headers: { Authorization: "JWT " + store.state.user.accessToken },
  });
}

export function autoDailyRoster(data) {
  return request({
    url: "user/auto_daily_roster/",
    method: "post",
    data,
    headers: { Authorization: "JWT " + store.state.user.accessToken },
  });
}

export function getCurrentRoster(params) {
  return request({
    url: "user/current_roster/",
    method: "get",
    params,
    headers: { Authorization: "JWT " + store.state.user.accessToken },
  });
}

export function createTransaction(data) {
  return request({
    url: "user/create_transaction/",
    method: "post",
    data,
    headers: { Authorization: "JWT " + store.state.user.accessToken },
  });
}

export function createTransactionOverMax(data) {
  return request({
    url: "user/create_transaction_over_max/",
    method: "post",
    data,
    headers: { Authorization: "JWT " + store.state.user.accessToken },
  });
}

export function getFantasySeasonLeagueList(params) {
  return request({
    url: "user/fantasy_season_league_list/",
    method: "get",
    params,
    headers: { Authorization: "JWT " + store.state.user.accessToken },
  });
}

export function getFantasySeasonLeagueRetrieve(params, data) {
  return request({
    url: "user/fantasy_season_league_retrieve/",
    method: "post",
    params,
    data,
    headers: { Authorization: "JWT " + store.state.user.accessToken },
  });
}

export function getFantasySeasonLeagueRanking(params) {
  return request({
    url: "user/fantasy_season_league_ranking/",
    method: "get",
    params,
    headers: { Authorization: "JWT " + store.state.user.accessToken },
  });
}

export function createLeague(data) {
  return request({
    url: "user/create_league/",
    method: "post",
    data,
    headers: { Authorization: "JWT " + store.state.user.accessToken },
  });
}

export function updateLeague(data) {
  return request({
    url: "user/update_league/",
    method: "patch",
    data,
    headers: { Authorization: "JWT " + store.state.user.accessToken },
  });
}

export function joinLeague(data) {
  return request({
    url: "user/join_league/",
    method: "post",
    data,
    headers: { Authorization: "JWT " + store.state.user.accessToken },
  });
}

export function quitLeague(data) {
  return request({
    url: "user/quit_league/",
    method: "post",
    data,
    headers: { Authorization: "JWT " + store.state.user.accessToken },
  });
}

export function cancelLeague(data) {
  return request({
    url: "user/cancel_league/",
    method: "post",
    data,
    headers: { Authorization: "JWT " + store.state.user.accessToken },
  });
}

export function kickUserFromLeague(data) {
  return request({
    url: "user/kick_user_from_league/",
    method: "post",
    data,
    headers: { Authorization: "JWT " + store.state.user.accessToken },
  });
}

export function getTransferList(params) {
  return request({
    url: "/user/list_transfer/",
    method: "get",
    headers: { Authorization: "JWT " + store.state.user.accessToken },
    params,
  });
}

export function getAchievement(params) {
  return request({
    url: "/user/check_achievement/",
    method: "get",
    headers: { Authorization: "JWT " + store.state.user.accessToken },
    params,
  });
}

export function checkoutAchievementReward(params) {
  return request({
    url: "/user/checkout_achievement_reward/",
    method: "get",
    headers: { Authorization: "JWT " + store.state.user.accessToken },
    params,
  });
}
